<template>
  <q-input
    :value="money.toString()"
    :prefix="currencySymbol"
    v-bind="$attrs"
    type="number"
    stack-label
    :color="color"
    :rounded="$q.platform.is.mobile"
    :outlined="$q.platform.is.mobile"
    :min="0"
    @blur="e => $emit('blur', e)"
    @change="e => money = e.target.value"
  />
</template>

<script>
import Money from 'js-money'

export default {
  props: {
    value: {
      required: true
    },
    currencySymbol: {
      type: String,
      default: '£'
    },
    currency: {
      type: String,
      default: 'GBP'
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    money: {
      get () {
        return this.value != null && this.value !== '' ? Money.fromInteger(this.value, Money[this.currency]) : ''
      },
      set (val) {
        if (val !== null && val !== '') {
          this.$emit('input',
            Money.fromDecimal(
              val,
              Money[this.currency],
              Math.floor
            ).amount
          )
        } else {
          this.$emit('input', null)
        }
      }
    }
  }
}
</script>

<style>

</style>
